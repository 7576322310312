<script setup >

const props = defineProps({
  size: {
    type: String,
    default: '10',
  },
  rounded: {
    type: Boolean,
    default: false,
  },
  src: {
    type: String,
    default: '',
  }
});

const classCompute = () => {
  let rounded = '';
  if(props.rounded){
    rounded = 'rounded-md';
  }
  return rounded;
}

</script>

<template>
  <img
      :width="size"
      :height="size"
      :class="[classCompute(),'inline-block rounded-full']"
      :src="src"
  />

</template>

<style lang="scss" scoped>
</style>
